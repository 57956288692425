import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import AxiosInstance from '../../Api/AxiosInstance'
import { Modal } from 'react-bootstrap'
import { useForm, } from 'react-hook-form'
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify'

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const MyAccount = () => {



  const editAdderesvalidation = Yup.object().shape({
    first_name: Yup.string().matches(/^[A-Za-z ]+$/, "First Name must contain only characters").required('First Name is required').trim(),
    last_name: Yup.string().matches(/^[A-Za-z ]+$/, "Last Name must contain only characters").required('Last Name is required').trim(),
    street_address_1: Yup.string().required('Street Address is required').trim(),
    pincode: Yup.string().required('Pincode is required').min(6, "Pincode must be 6 digit").max(6, 'Pincode must be 6 digit').trim(),
    state_id: Yup.string().required('State is required'),
    city_id: Yup.string().required('City is required'),
    phone_no: Yup.string().required('Mobile No. is required')
      .matches(phoneRegExp, "Mobile No. must be 10 digit").min(10, "Mobile No. must be 10 digit").max(10, "Mobile No. must be 10 digit"),
    email: Yup.string().email("Enater valid Email Id").required('Email ID is required'),
  })

  const user = JSON.parse(localStorage.getItem('user'));
  const [addressDetail, setAddressDetail] = useState([])
  const [myOrder, setMyOrder] = useState([])
  const [orderpop, setOrderpop] = useState(false)
  const [orderDetail, setOrderDetail] = useState([])
  const [DetailOrder, setDetailOrder] = useState([])
  const [paymentHistory, setPaymentHistory] = useState([])
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [City, setCity] = useState([]);
  const [relation, setRelation] = useState([])
  const [id_proof, setId_proof] = useState([])
  const [editpop, setEditpop] = useState(false)
  const [selectaddress, setSelectaddress] = useState([])
  const [selectedRelation, setSelectedRelation] = useState('')
  const [selectedIdProof, setSelectedIdProof] = useState('')
  const [addresscity, setaddresscity] = useState('')
  const [addressstate, setAddressstate] = useState('')
  const [emailerr, setEmailerr] = useState('')
  const [maxDate, setMaxDate] = useState('');
  const navigate = useNavigate()
  const [GENDER, setGENDER] = useState('')
  const [panfile, setPanfile] = useState([])
  const [idfile, setIdfile] = useState([])
  const [Profile, setProfile] = useState(false)
  const [userData, setUserData] = useState([])
  const [kycFiles, setKycFiles] = useState({
    1: [], // PAN Card
    6: [], // Cancel Cheque
    2: [], // ID Proof (for id_proof_id 2, 3, 4, 5)
    3: [],
    4: [],
    5: [],
  });


  const { register, handleSubmit, setValue, control, formState: { errors }, getValues, clearErrors } = useForm({});

  const handleSelectEditAddress = async (id) => {
    const response = await AxiosInstance.post('billing-address', { address_id: id })
    setSelectaddress(response.data.data)
    setEditpop(true)
    setaddresscity(response.data.data.city_name)
    setAddressstate(response.data.data.state_name)
  }

  const handlePanChange = (event) => {
    const files = Array.from(event.target.files);
    setPanfile((prevFiles) => [...prevFiles, ...files]);
  };

  const handleIdProoffileChange = (event) => {
    const files = Array.from(event.target.files);
    setIdfile((prevFiles) => [...prevFiles, ...files]);
  };

  useEffect(() => {
    const fetchUserDetail = async () => {
      try {
        const response = await AxiosInstance.post('my-account-details')
        setUserData(response.data.data)
        const kycDetails = response.data.data.kyc_details || [];
        const groupedFiles = { 1: [], 6: [], 2: [], 3: [], 4: [], 5: [] };
        (kycDetails || []).forEach(detail => {
          if (groupedFiles[detail.id_proof_id]) {
            groupedFiles[detail.id_proof_id].push(detail);
          }
        });
        setKycFiles(groupedFiles);
        setGENDER(response.data.data.gender)
        setSelectedOption(response.data.data.address_details[0].state_id)
        setSelectedCity(response.data.data.address_details[0].city_id)
        setSelectedRelation(response.data.data.relation_with_applicant)
        setSelectedIdProof(response.data.data.id_proof_id)

        setValue('id', response.data.data.id);
        setValue('first_name', response.data.data.first_name);
        setValue('middle_name', response.data.data.middle_name);
        setValue('last_name', response.data.data.last_name);
        setValue('email', response.data.data.email);
        setValue('mobile_no', response.data.data.mobile_no);
        setValue('phone_no', response.data.data.phone_no);
        setValue('date_of_birth', response.data.data.date_of_birth);
        setValue('gender', response.data.data.gender);
        setValue('state_id', response.data.data.address_details[0].state_id);
        setValue('city_id', response.data.data.address_details[0].city_id);
        setValue('pincode', response.data.data.address_details[0].pincode);
        setValue('address', response.data.data.address_details[0].street_address_1);
        setValue('nominee_name', response.data.data.nominee_name);
        setValue('nominee_age', response.data.data.nominee_age);
        setValue('relation_with_applicant', response.data.data.relation_with_applicant);
        setValue('gst_no', response.data.data.gst_no == "null" ? "" : response.data.data.gst_no);
        setValue('pan', response.data.data.pan);
        setValue('id_proof_id', response.data.data.id_proof_id);
        setValue('bank_name', response.data.data.bank_name);
        setValue('account_type', response.data.data.account_type);
        setValue('account_holder_name', response.data.data.account_holder_name);
        setValue('bank_account_number', response.data.data.bank_account_number);
        setValue('branch_name_and_address', response.data.data.branch_name_and_address);
        setValue('bank_ifsc_no', response.data.data.bank_ifsc_no);
        setValue('branch_city', response.data.data.branch_city);
      } catch (error) {

      }
    }
    fetchUserDetail()
  }, [Profile])


  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    setMaxDate(formattedDate);
  }, []);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleRelationChange = (e) => {
    setSelectedRelation(e.target.value)
  }

  const handleIdProofChange = (e) => {
    setSelectedIdProof(e.target.value)
  }

  const billingAddress = async () => {
    try {
      const response = await AxiosInstance.post('billing-address-list')
      setAddressDetail(response.data.data)
    } catch (error) {

    }
  }

  const handleUpdateAddress = async (value) => {
    try {
      const response = await AxiosInstance.post('add-user-address', value);
      if (response.data.success) {
        setEditpop(false)
        toast.success("Address Update Successfully.")
      }
    } catch (error) {
      console.error("Error updating address:", error); // Log any errors
    }
  };


  useEffect(() => {
    const allOrder = async () => {
      try {
        const response = await AxiosInstance.post('my-orders')
        setMyOrder(response.data.data)
      } catch (error) {

      }
    }
    allOrder()
  }, [])


  const handleView = async (id) => {
    try {
      const response = await AxiosInstance.post('order-details', { order_id: id })
      if (response.data.success) {
        setOrderpop(true)
        setOrderDetail(response.data.data)
        setDetailOrder(response.data.data.order_details)
        setPaymentHistory(response.data.data.payment_history[0])
      }
    } catch (error) {

    }
  }


  useEffect(() => {
    billingAddress()
  }, [editpop])

  useEffect(() => {
    const registrationDropdown = async () => {
      const response = await AxiosInstance.post('registration-option-details')
      setId_proof(response.data.data.id_proofs)
      setRelation(response.data.data.relations)
    }
    registrationDropdown()
  }, [])


  useEffect(() => {
    if (selectedOption) {
      AxiosInstance.post('cities', { state_id: selectedOption })
        .then(response => {
          const cities = response.data?.data?.cities;
          if (Array.isArray(cities)) {
            const options1 = cities.map(option => ({
              value: option.id,
              label: option.name,
            }));
            setCity(options1);
          } else {
            setCity([]); // Set city options to an empty array or handle it as per your requirement
          }
        })
        .catch(error => {
          setCity([]); // Optionally, set city options to an empty array in case of error
        });
    }
  }, [selectedOption]);


  const handleStateChange = (e) => {
    setSelectedOption(e.target.value);
    setSelectedCity('');
  };

  const handleCityChange = (e) => {
    setSelectedCity(e.target.value);
  };

  useEffect(() => {

    const fetchState = async () => {
      try {
        const response = await AxiosInstance.post('states')
        setOptions(response.data.data.states)
      } catch (error) {

      }
    }
    fetchState()
  }, []);

  const handleStateSelect = async (value, setFieldValue) => {
    try {
      const response = await AxiosInstance.post('cities', { state_id: value })
      setCity(response.data.data.cities)
      setaddresscity('')
      setAddressstate('')
      setFieldValue('city_id', '');
    } catch (error) {

    }

  };


  const handleRegister = async (data) => {

    try {
      const token = user.access_token
      data = { ...data, pan_card: panfile, id_proof: idfile, cancel_cheque: Array.from(data.cancel_cheque) }

      const formData = new FormData();

      // Append each key-value pair to the FormData instance
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          if (Array.isArray(data[key])) {
            data[key].forEach((item, index) => {
              formData.append(`${key}[${index}]`, item);
            });
          } else {
            formData.append(key, data[key]);
          }
        }
      }

      fetch(process.env.REACT_APP_API_URL + 'user/update', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            setEmailerr('')
            scrollToTop()
            toast.success("Profile updated successfully.")
            scrollToTop()
            setProfile(!Profile)


          } else if (data.data.message?.email[0] == "The email  has already been taken.") {
            setEmailerr("This email has already been used.")
            toast.error("This email has already been used.")
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });



    } catch (error) {
      console.log(error)
    }


  };


  const validateGstNo = (value) => {
    if (value == '' || value == null) {
      return true; // If the field is empty, it's valid
    }
    const gstNoRegex = /^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[A-Za-z0-9]{3}$/;
    return gstNoRegex.test(value) || 'Enter a valid GST No.';
  };

  const handleRadioChange = (event) => {
    setGENDER(event.target.value);
  };


  const logout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    scrollToTop();
    navigate("/");
    setTimeout(() => {
      window.location.reload()
    }, 1000);

    toast.success('Logout successfully!', {
      position: "top-right",
      autoClose: 1000,
    });
  };

  return (
    <>
      <div className="wrapper">
        <div className="blank_header trans"></div>
        <main className="main-content">
          <section className="page-header-area pt-10 pb-9" data-bg-color="#FFF3DA" style={{ backgroundColor: "#FFF3DA" }}>
            <div className="container">
              <div className="row">
                <div className="col-md-5">
                  <div className="page-header-st3-content text-center text-md-start">
                    <ol className="breadcrumb justify-content-center justify-content-md-start">
                      <li className="breadcrumb-item"><Link className="text-dark" onClick={scrollToTop} to="/">Home</Link></li>
                      <li className="breadcrumb-item active text-dark" aria-current="page">My Account</li>
                    </ol>
                    <h2 className="page-header-title">My Account</h2>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="my-account-area section-space">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-4">
                  <div className="my-account-tab-menu nav nav-tabs" id="nav-tab" role="tablist">
                    <button className="nav-link active" id="dashboad-tab" data-bs-toggle="tab" data-bs-target="#dashboad" type="button" role="tab" aria-controls="dashboad" aria-selected="true">Dashboard</button>
                    <button className="nav-link" id="orders-tab" data-bs-toggle="tab" data-bs-target="#orders" type="button" role="tab" aria-controls="orders" aria-selected="false"> Orders</button>
                    {/* <button className="nav-link" id="download-tab" data-bs-toggle="tab" data-bs-target="#download" type="button" role="tab" aria-controls="download" aria-selected="false">Download</button> */}
                    {/* <button className="nav-link" id="payment-method-tab" data-bs-toggle="tab" data-bs-target="#payment-method" type="button" role="tab" aria-controls="payment-method" aria-selected="false">Payment Method</button> */}
                    <button className="nav-link" id="address-edit-tab" data-bs-toggle="tab" data-bs-target="#address-edit" type="button" role="tab" aria-controls="address-edit" aria-selected="false">address</button>
                    <button className="nav-link" id="account-info-tab" data-bs-toggle="tab" data-bs-target="#account-info" type="button" role="tab" aria-controls="account-info" aria-selected="false">Account Details</button>
                    <button className="nav-link" onClick={logout} type="button">Logout</button>
                  </div>
                </div>
                <div className="col-lg-9 col-md-8">
                  <div className="tab-content" id="nav-tabContent">

                    <div className="tab-pane fade show active" id="dashboad" role="tabpanel" aria-labelledby="dashboad-tab">
                      <div className="myaccount-content">
                        <h3>Dashboard</h3>
                        <div className="welcome">
                          <p>Hello, <strong>{user?.user?.first_name}</strong>
                            {/* (<Link onClick={scrollToTop} to="/login" className="logout"><u>Logout</u></Link>) */}
                          </p>
                        </div>
                        <p>From your account dashboard. you can easily check & view your recent orders, manage your shipping and billing addresses and edit your password and account details.</p>
                      </div>
                    </div>

                    <div className="tab-pane fade" id="orders" role="tabpanel" aria-labelledby="orders-tab">
                      <div className="myaccount-content">
                        <h3>Orders</h3>
                        <div className="myaccount-table table-responsive text-center">
                          <table className="table table-bordered">
                            <thead className="thead-light">
                              <tr>
                                <th>Order</th>
                                <th>Date</th>
                                <th>Status</th>
                                <th>Total</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                myOrder.map((item, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.order_date}</td>
                                    <td>{item.delivered_status == 0 || item.delivered_status == 1 ? 'Pending' : item.delivered_status == 2 ? 'Awaiting Pickup' :
                                      item.delivered_status == 3 ? 'Partially Shipped' : item.delivered_status == 4 ? "Completed" : item.delivered_status == 5 ? "Dispatched" : ''}</td>
                                    <td>₹ {item.total_amount?.toFixed(2)}</td>
                                    <td><Link onClick={() => handleView(item.id)} className="check-btn sqr-btn">View</Link></td>
                                  </tr>
                                ))
                              }

                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>



                    <div className="tab-pane fade" id="address-edit" role="tabpanel" aria-labelledby="address-edit-tab">
                      <div className="myaccount-content">
                        <h3>Billing Address</h3>

                        <div className='row address-main'>
                          {
                            addressDetail.map((data) => (
                              <div className='col-lg-6 col-md-6' key={data.id}>
                                <address className='address-box'>
                                  <p > <strong>{data.first_name} {data.last_name}</strong> <br />{data.street_address_1} , {data.city_name} , {data.state_name} , {data.pincode}</p>
                                  <p>Mobile: <strong>{data.phone_no}</strong></p>
                                  <Link onClick={() => handleSelectEditAddress(data.id)} className="check-btn sqr-btn bg-white"><i className="fa fa-edit"></i> Edit Address</Link>
                                </address>
                              </div>
                            ))
                          }

                        </div>

                      </div>
                    </div>

                    <div className="tab-pane fade" id="account-info" role="tabpanel" aria-labelledby="account-info-tab">
                      <div className="myaccount-content my-account-form-wrap">
                        <h3>Account Details</h3>
                        <div className="account-details-form my-account-form">
                          <form onSubmit={handleSubmit(handleRegister)}>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group mb-6">
                                  <label htmlFor="first_name">First Name<sup>*</sup></label>
                                  <input type="text" id="first_name" placeholder='First Name' {...register('first_name', {
                                    required: "First Name is required",
                                    pattern: {
                                      value: /^[A-Za-z][A-Za-z\s]*$/,
                                      message: "First Name must contain only characters"
                                    }
                                  })} />
                                  {errors.first_name && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.first_name.message}</p>}
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group mb-6">
                                  <label htmlFor="middle_name">Middle Name<sup>*</sup></label>
                                  <input type="text" id="middle_name" placeholder='Middle Name' {...register('middle_name', {
                                    required: "Middle Name is required",
                                    pattern: {
                                      value: /^[A-Za-z][A-Za-z\s]*$/,
                                      message: "Middle Name must contain only characters"
                                    }
                                  })} />
                                  {errors.middle_name && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.middle_name.message}</p>}
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group mb-6">
                                  <label htmlFor="last_name">Last Name<sup>*</sup></label>
                                  <input type="text" id="last_name" placeholder='Last Name' {...register('last_name', {
                                    required: "Last Name is required",
                                    pattern: {
                                      value: /^[A-Za-z][A-Za-z\s]*$/,
                                      message: "Last Name must contain only characters"
                                    }
                                  })} />
                                  {errors.last_name && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.last_name.message}</p>}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group mb-6">
                                  <label htmlFor="email">Email ID<sup>*</sup></label>
                                  <input type="email" id="email" placeholder='Email ID' {...register('email', {
                                    required: "Email ID is required",
                                    pattern: {
                                      value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                                      message: "Enter a valid Email ID",
                                    }
                                  })} />
                                  {errors.email && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.email.message}</p>}
                                  {emailerr && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{emailerr}</p>}
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group mb-6">
                                  <label htmlFor="mobile_no">Mobile Number<sup>*</sup></label>
                                  <input type="number" id="mobile_no" placeholder='Mobile Number'
                                    onInput={(e) => {
                                      e.target.value = e.target.value.replace(/\D/g, '');
                                      e.target.value = e.target.value.slice(0, 10);
                                    }}
                                    {...register('mobile_no', {
                                      required: "Mobile Number is required",
                                      minLength: {
                                        value: 10,
                                        message: "Mobile Number must be 10 digits"
                                      },
                                      maxLength: {
                                        value: 10,
                                        message: "Mobile Number must be 10 digits"
                                      },
                                      pattern: {
                                        value: /^[1-9][0-9]{9}$/, // Pattern to ensure mobile number doesn't start with 0
                                        message: "Enter valid Mobile Number"
                                      }
                                    })} />
                                  {errors.mobile_no && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.mobile_no.message}</p>}
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group mb-6">
                                  <label htmlFor="phone_no">Phone Number<sup>*</sup></label>
                                  <input type="number" id="phone_no" placeholder='Phone Number'
                                    onInput={(e) => {
                                      e.target.value = e.target.value.replace(/\D/g, '');
                                      e.target.value = e.target.value.slice(0, 10);
                                    }}
                                    {...register('phone_no', {
                                      required: "Phone Number is required",
                                      minLength: {
                                        value: 10,
                                        message: "Phone Number must be 10 digits"
                                      },
                                      maxLength: {
                                        value: 10,
                                        message: "Phone Number must be 10 digits"
                                      },
                                      pattern: {
                                        value: /^[1-9][0-9]{9}$/, // Pattern to ensure mobile number doesn't start with 0
                                        message: "Enter valid Phone Number"
                                      }
                                    })} />
                                  {errors.phone_no && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.phone_no.message}</p>}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group mb-6">
                                  <label htmlFor="date_of_birth">Date Of Birth<sup>*</sup></label>
                                  <input type="date" max={maxDate} onKeyDown={(e) => e.preventDefault()} id="date_of_birth" {...register('date_of_birth', {
                                    required: "Date Of Birth is required",
                                  })} />
                                  {errors.date_of_birth && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.date_of_birth.message}</p>}
                                </div>
                              </div>

                              <div className="col-md-3">
                                <div className="form-group mb-6 radio-btn">
                                  <label htmlFor="gender">Gender<sup>*</sup></label>
                                  <div className='d-flex'>
                                    <div className='radio-item d-flex'>
                                      <input type='radio' name='gender' checked={GENDER == '2'} value='2' {...register("gender", {
                                        required: "Gender is required",
                                        onChange: handleRadioChange
                                      })}
                                      /> <label>Male</label>
                                    </div>
                                    <div className='radio-item d-flex'>
                                      <input type='radio' name='gender' checked={GENDER == '1'} value='1' {...register('gender')} /> <label> Female</label>
                                    </div>
                                  </div>
                                  {errors.gender && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.gender.message}</p>}
                                </div>
                              </div>
                            </div>
                            <hr />
                            <h3 className="title">Address Details</h3>
                            <div className="row">

                              <div className="col-md-4">
                                <div className="form-group mb-6">
                                  <label htmlFor="nominee">State<sup>*</sup></label>
                                  <div className='selectbox'>
                                    <select name="state_id" id="state" value={selectedOption}
                                      {...register('state_id', {
                                        required: "State is required",
                                        onChange: handleStateChange
                                      })}>
                                      <option value=''> Select State</option>
                                      {
                                        options.map((item) => (
                                          <option value={item.id} key={item.id}>{item.name}</option>
                                        ))
                                      }
                                    </select>
                                  </div>
                                  {errors.state_id && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.state_id.message}</p>}
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group mb-6">
                                  <label htmlFor="nominee">City<sup>*</sup></label>
                                  <div className='selectbox'>
                                    <select name="city_id" id="city" value={selectedCity}
                                      {...register('city_id', {
                                        required: "City is required",
                                        onChange: handleCityChange
                                      })}>
                                      <option value="">Select City</option>
                                      {
                                        City.map((item) => (
                                          <option value={item.value} key={item.id}>{item.label}</option>
                                        ))
                                      }
                                    </select>
                                  </div>
                                  {errors.city_id && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.city_id.message}</p>}
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group mb-6">
                                  <label htmlFor="postal_code">Postal Code<sup>*</sup></label>
                                  <input type="number" id="postal_code" placeholder='Postal Code'
                                    onInput={(e) => {
                                      e.target.value = e.target.value.replace(/\D/g, '');
                                      e.target.value = e.target.value.slice(0, 6);
                                    }}
                                    {...register('pincode', {
                                      required: "Postal Code is required",
                                      minLength: {
                                        value: 6,
                                        message: "Postal Code must be 6 digits"
                                      },
                                      maxLength: {
                                        value: 6,
                                        message: "Postal Code must be 6 digits"
                                      }
                                    })} />
                                  {errors.pincode && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.pincode.message}</p>}
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group mb-6">
                                  <label htmlFor="address">Address<sup>*</sup></label>
                                  <input type="text" id="address" placeholder='Address' {...register('address', {
                                    required: "Address is required",
                                  })} />
                                  {errors.address && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.address.message}</p>}
                                </div>
                              </div>
                              <hr />
                              <h3 className="title">Add Nominee Information</h3>

                              <div className="col-md-4">
                                <div className="form-group mb-6">
                                  <label htmlFor="confirm_password" >Nominee Name<sup>*</sup></label>
                                  <input type="text" placeholder='Nominee Name' {...register('nominee_name', {
                                    required: "Nominee Name is required",
                                    pattern: {
                                      value: /^[A-Za-z][A-Za-z\s]*$/,
                                      message: "Nominee Name must contain only characters"
                                    }
                                  })} />
                                  {errors.nominee_name && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.nominee_name.message}</p>}
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group mb-6">
                                  <label htmlFor="confirm_password">Nominee Age<sup>*</sup></label>
                                  <input type="number" placeholder='Nominee Age'
                                    onInput={(e) => {
                                      e.target.value = e.target.value.replace(/\D/g, '');
                                      e.target.value = e.target.value.slice(0, 3);
                                    }}
                                    {...register('nominee_age', {
                                      required: "Nominee Age is required",
                                    })} />
                                  {errors.nominee_age && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.nominee_age.message}</p>}
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group mb-6">
                                  <label htmlFor="nominee">Relation With Applicant<sup>*</sup></label>
                                  <div className='selectbox'>
                                    <select name="relation" id="nominee" value={selectedRelation}
                                      {...register('relation_with_applicant', {
                                        required: "Select Relation",
                                        onChange: handleRelationChange
                                      })}>
                                      <option value="">Select Nominee Relation</option>
                                      {
                                        relation.map((item) => (
                                          <option value={item.id} key={item.id}>{item.value}</option>
                                        ))
                                      }
                                    </select>
                                  </div>
                                  {errors.relation_with_applicant && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.relation_with_applicant.message}</p>}
                                </div>
                              </div>


                              <hr />
                              <h3 className="title">Add KYC Details</h3>


                              <div className="col-md-4">
                                <div className="form-group mb-6">
                                  <label htmlFor="gst_No">GST No.</label>
                                  <input type="text" id='gst_No' placeholder='29GGGGG1314R9Z6' maxLength={15}
                                    {...register('gst_no', {
                                      validate: validateGstNo,
                                    })} />
                                  {errors.gst_no && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.gst_no.message}</p>}
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group mb-6">
                                  <label htmlFor="Pan_No">PAN No.<sup>*</sup></label>
                                  {userData?.kyc_status == 3 ?
                                    <input type="text" id="Pan_No" disabled maxLength={10} placeholder='ABCTY1234D'
                                      {...register('pan', {
                                        required: "PAN No. is required",
                                        pattern: {
                                          value: /^[a/A-z/Z]{5}[0-9]{4}[a/A-z/Z]{1}$/,  // Regular expression pattern for PAN card
                                          message: "Enter a valid PAN No."
                                        }
                                      })}
                                    />
                                    :
                                    <input type="text" id="Pan_No"  maxLength={10} placeholder='ABCTY1234D'
                                      {...register('pan', {
                                        required: "PAN No. is required",
                                        pattern: {
                                          value: /^[a/A-z/Z]{5}[0-9]{4}[a/A-z/Z]{1}$/,  // Regular expression pattern for PAN card
                                          message: "Enter a valid PAN No."
                                        }
                                      })}
                                    />
                                  }

                                  {errors.pan && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.pan.message}</p>}
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group mb-6">
                                  <label htmlFor="selectId">Select ID Proof<sup>*</sup></label>
                                  <div className='selectbox'>
                                    {
                                      userData?.kyc_status == 3 ?
                                        <select id="selectId" value={selectedIdProof} disabled  {...register('id_proof_id', {
                                          required: "Select Id Proof",
                                          onChange: handleIdProofChange
                                        })}>
                                          <option value="">Select ID Proof</option>
                                          {
                                            id_proof.map((item) =>
                                              <option value={item.id} key={item.id}>{item.value}</option>
                                            )
                                          }
                                        </select>
                                        :
                                        <select id="selectId" value={selectedIdProof}  {...register('id_proof_id', {
                                          required: "Select Id Proof",
                                          onChange: handleIdProofChange
                                        })}>
                                          <option value="">Select ID Proof</option>
                                          {
                                            id_proof.map((item) =>
                                              <option value={item.id} key={item.id}>{item.value}</option>
                                            )
                                          }

                                        </select>

                                    }

                                  </div>
                                  {errors.id_proof_id && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.id_proof_id.message}</p>}
                                </div>
                              </div>
                            </div>

                            <div className='row'>
                              <div className="col-md-4">
                                <div className="form-group ">
                                  <label >ID Proof<sup>*</sup></label>
                                  {
                                    userData?.kyc_status !== 3 &&
                                    <input type="file" multiple
                                      {...register('id_proof', {
                                        onChange: handleIdProoffileChange
                                      })} />
                                  }
                                </div>
                                {kycFiles[2].concat(kycFiles[3], kycFiles[4], kycFiles[5]).map(file => (
                                  <ul key={file.id}>
                                    <li> <Link className="lost-password" target='_blank' to={`${file.document_url}`}>{file.file_name} </Link></li>
                                  </ul>
                                ))}
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label >PAN Card<sup>*</sup></label>
                                  {userData?.kyc_status !== 3 &&
                                    <input type="file" multiple  {...register('pan_card', {
                                      onChange: handlePanChange
                                    })} />}
                                </div>
                                {kycFiles[1].map(file => (
                                  <ul key={file.id}>
                                    <li> <Link className='lost-password' target='_blank' to={`${file.document_url}`}>{file.file_name} </Link></li>
                                  </ul>
                                ))}
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label htmlFor="confirm_password">Cancel Cheque<sup>*</sup></label>
                                  {userData?.kyc_status !== 3 &&
                                    <input type="file"  {...register('cancel_cheque')} />}
                                </div>
                                {kycFiles[6].map(file => (
                                  <ul key={file.id}>
                                    <li> <Link className='lost-password' target='_blank' to={`${file.document_url}`}>{file.file_name} </Link></li>
                                  </ul>
                                ))}
                              </div>
                            </div>

                            <hr />

                            <h3 className="title">Add Bank Details</h3>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group mb-6">
                                  <label htmlFor="Bank Name">Bank Name<sup>*</sup></label>
                                  <input type="text" id="Bank Name" placeholder='Bank Name'  {...register('bank_name', {
                                    required: "Bank Name is required",
                                    pattern: {
                                      value: /^[A-Za-z][A-Za-z\s]*$/,
                                      message: "Bank Name must contain only characters"
                                    }
                                  })} />
                                  {errors.bank_name && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.bank_name.message}</p>}
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group mb-6">
                                  <label htmlFor="Account_Type">Account Type<sup>*</sup></label>
                                  <div className='selectbox'>
                                    <select name="relation" id="nominee" {...register('account_type', {
                                      required: "Account Type is required",
                                    })}>
                                      <option value="">Select Account Type</option>
                                      <option value="1">Saving</option>
                                      <option value="2">Current</option>
                                    </select>
                                  </div>
                                  {errors.account_type && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.account_type.message}</p>}
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group mb-6">
                                  <label htmlFor="Account_Holder_Name">Account Holder Name<sup>*</sup></label>
                                  <input type="text" id="Account_Holder_Name" placeholder='>Account Holder Name' {...register('account_holder_name', {
                                    required: "Account Holder Name is required",
                                    pattern: {
                                      value: /^[A-Za-z][A-Za-z\s]*$/,
                                      message: "Account Holder Name must contain only characters"
                                    }
                                  })} />
                                  {errors.account_holder_name && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.account_holder_name.message}</p>}
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group mb-6">
                                  <label htmlFor="Bank A/C No">Bank A/C No.<sup>*</sup></label>
                                  <input type="text" id="Bank A/C No" placeholder='Bank A/C No.'
                                    onInput={(e) => {
                                      e.target.value = e.target.value.replace(/\D/g, '');
                                      e.target.value = e.target.value.slice(0, 16);
                                    }}
                                    {...register('bank_account_number', {
                                      required: "Bank Account No. is required",
                                    })} />
                                  {errors.bank_account_number && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.bank_account_number.message}</p>}
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group mb-6">
                                  <label htmlFor="Branch_name">Branch Name & Address<sup>*</sup></label>
                                  <input type="text" id="Branch_name" placeholder='Branch Name & Address' {...register('branch_name_and_address', {
                                    required: "Bank Branch Name is required",
                                  })} />
                                  {errors.branch_name_and_address && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.branch_name_and_address.message}</p>}
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group mb-6">
                                  <label htmlFor="IFSC_No">IFSC No.<sup>*</sup></label>
                                  <input type="text" id="IFSC_No" placeholder='SBIN0005943' maxLength={11}  {...register('bank_ifsc_no', {
                                    required: "IFSC No. is required",
                                    pattern: {
                                      value: /^[A-Z]{4}0[A-Z0-9]{6}$/, // Regular expression pattern for IFSC code
                                      message: "Enter a valid IFSC No."
                                    }
                                  })} />
                                  {errors.bank_ifsc_no && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.bank_ifsc_no.message}</p>}
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group mb-6">
                                  <label htmlFor="Branch_City">Branch City<sup>*</sup></label>
                                  <input type="text" id="Branch_City" placeholder='Branch City' {...register('branch_city', {
                                    required: "Branch City is required",
                                  })} />
                                  {errors.branch_city && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.branch_city.message}</p>}
                                </div>
                              </div>
                            </div>

                            <div className="form-group d-flex">
                              <button className="btn ml-auto" type="submit">Submit</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
      <Modal show={orderpop} onHide={() => setOrderpop(false)} centered size='lg'>
        <button type="button" className="btn-close" >
          <span className="fa fa-close" onClick={() => setOrderpop(false)}></span>
        </button>
        <div className='tablestyle myaccount-table table-responsive p-5'>
          <table className='table table-bordered'>
            <thead className='thead-light'>
              <tr>
                <th>Product</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {
                DetailOrder.map((item) => (
                  <tr key={item.id}>
                    <td>{item.product_name} x {item.quantity}</td>
                    <td>₹ {item.sub_total?.toFixed(2)}</td>
                  </tr>
                ))
              }
              <tr>
                <td>Subtotal:</td>
                <td>₹ {orderDetail?.sub_total?.toFixed(2)}</td>
              </tr>
              <tr>
                <td>Payment Method:</td>
                <td>{paymentHistory?.payment_method ? paymentHistory?.payment_method : 'Cash on delivery'}</td>
              </tr>
              <tr>
                <td>Total:</td>
                <td>₹ {orderDetail?.total_amount?.toFixed(2)}</td>
              </tr>
              <tr>
                <td>Billing Address:</td>
                <td>{orderDetail?.billing_address?.street_address_1} , {orderDetail?.billing_address?.city_name} , {orderDetail?.billing_address?.state_name} - {orderDetail?.billing_address?.pincode}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>

      <Modal show={editpop} onHide={() => setEditpop(false)} centered >
        <button type="button" className="btn-close" >
          <span className="fa fa-close" onClick={() => setEditpop(false)}></span>
        </button>
        <div className=" m-3 my-account-form">
          <div className="mb-2">
            <h4>Account Details</h4>
          </div>
          <Formik
            initialValues={{
              id: selectaddress ? selectaddress.id : '',
              first_name: selectaddress ? selectaddress?.first_name : "",
              company_name: selectaddress ? selectaddress?.company_name : "",
              last_name: selectaddress ? selectaddress?.last_name : "",
              street_address_2: selectaddress?.street_address_2 == "null" ? "" : selectaddress?.street_address_2,
              street_address_1: selectaddress ? selectaddress?.street_address_1 : "",
              state_id: selectaddress?.state_id,
              pincode: selectaddress ? selectaddress?.pincode : "",
              city_id: selectaddress?.city_id,
              phone_no: selectaddress ? selectaddress?.phone_no : "",
              email: selectaddress ? selectaddress?.email : ""
            }}
            validationSchema={editAdderesvalidation}
            onSubmit={handleUpdateAddress}>
            {({ handleSubmit, values, handleChange, setFieldValue }) => (
              <form onSubmit={handleSubmit}>
                <div className='row'>
                  <div className="col-md-6 mb-2">
                    <div className="form-group">
                      <label htmlFor="f_name">First Name <abbr className="required" title="required">*</abbr></label>
                      <input name='first_name' onChange={handleChange} id="f_name" placeholder='Enter First Name' type="text" className="form-control"
                        value={values.first_name}
                      />
                      <ErrorMessage name="first_name" >
                        {msg => (
                          <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{msg}</p>
                        )}
                      </ErrorMessage>
                    </div>
                  </div>
                  <div className="col-md-6 mb-2">
                    <div className="form-group">
                      <label htmlFor="l_name">Last Name <abbr className="required" title="required">*</abbr></label>
                      <input onChange={handleChange} id="l_name" placeholder='Enter Last Name' type="text" className="form-control"
                        value={values.last_name} name='last_name' />
                      <ErrorMessage name="last_name" >
                        {msg => (
                          <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{msg}</p>
                        )}
                      </ErrorMessage>
                    </div>
                  </div>
                  <div className="col-md-12 mb-2">
                    <div className="form-group">
                      <label htmlFor="com_name">Company Name (optional)</label>
                      <input onChange={handleChange} id="com_name" name='company_name' placeholder='Enter Company Name' type="text"
                        className="form-control" value={values.company_name} />
                    </div>
                  </div>
                  <div className="col-md-12 mb-4">
                    <div className="form-group mb-3">
                      <label htmlFor="street-address">Street address <abbr className="required" title="required">*</abbr></label>
                      <input onChange={handleChange} id="street-address" type="text" className="form-control " placeholder="House No. and Street Name"
                        value={values.street_address_1} name='street_address_1' />
                      <ErrorMessage name="street_address_1" >
                        {msg => (
                          <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{msg}</p>
                        )}
                      </ErrorMessage>
                    </div>
                    <div className="form-group">
                      <label htmlFor="street-address2" className="visually-hidden">Street address 2 <abbr className="required" title="required">*</abbr></label>
                      <input onChange={handleChange} id="street-address2" type="text" className="form-control" placeholder="Apartment, Suite, Unit etc. (Optional)"
                        value={values.street_address_2} name='street_address_2' />
                    </div>
                  </div>

                  <div className="col-md-6 mb-2">
                    <div className="form-group">
                      <label htmlFor="nominee">State<sup>*</sup></label>
                      <div className='selectbox'>
                        <select name="state_id" id="state"
                          onChange={(e) => {
                            handleChange(e);
                            handleStateSelect(e.target.value, setFieldValue);
                          }}
                        >
                          <option value=''>{addressstate ? addressstate : "Select State"}</option>
                          {/* <option value="">Select State</option> */}
                          {
                            options.map((item) => (
                              <option value={item.id} key={item.id}>{item.name}</option>
                            ))
                          }
                        </select>
                      </div>
                      <ErrorMessage name="state_id" >
                        {msg => (
                          <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{msg}</p>
                        )}
                      </ErrorMessage>
                    </div>
                  </div>

                  <div className="col-md-6 mb-2">
                    <div className="form-grou">
                      <label htmlFor="nominee">State<sup>*</sup></label>
                      <div className='selectbox'>
                        <select name="city_id" id="city"
                          onChange={(e) => {
                            handleChange(e);
                            // handleCityChange(e.target.value, setFieldValue);
                          }}>
                          <option value=''>{addresscity ? addresscity : "Select City"}</option>
                          {/* <option value="">Select City</option> */}
                          {/* <option value={item.value} key={item.id}>{item.label}</option> */}
                          {
                            City.map((item) => (
                              <option value={item.id} key={item.id}>{item.name}</option>
                            ))
                          }
                        </select>
                      </div>
                      <ErrorMessage name="city_id" >
                        {msg => (
                          <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{msg}</p>
                        )}
                      </ErrorMessage>
                    </div>
                  </div>

                  <div className="col-md-12 mb-2">
                    <div className="form-group">
                      <label htmlFor="pz-code">Postcode / ZIP <abbr className="required" title="required">*</abbr></label>
                      <input onChange={handleChange} id="pz-code" placeholder='Enter Postcode' type="number" className="form-control"
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(/\D/g, '');
                          e.target.value = e.target.value.slice(0, 6);
                        }} value={values.pincode} name='pincode' />
                      <ErrorMessage name="pincode" >
                        {msg => (
                          <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{msg}</p>
                        )}
                      </ErrorMessage>
                    </div>
                  </div>
                  <div className="col-md-12 mb-2">
                    <div className="form-group">
                      <label htmlFor="phone">Mobile No. <abbr className="required" title="required">*</abbr></label>
                      <input onChange={handleChange} id="phone" placeholder='Enter Mobile No.' type="number" className="form-control"
                        value={values.phone_no} name='phone_no'
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(/\D/g, '');
                          e.target.value = e.target.value.slice(0, 10);
                        }} />

                      <ErrorMessage name="phone_no" >
                        {msg => (
                          <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{msg}</p>
                        )}
                      </ErrorMessage>

                    </div>
                  </div>
                  <div className="col-md-12 mb-2">
                    <div className="form-group">
                      <label htmlFor="email">Email ID <abbr className="required" title="required">*</abbr></label>
                      <input onChange={handleChange} id="email" type="email" placeholder='Enter Email ID' className="form-control"
                        value={values.email} name='email' />
                      <ErrorMessage name="email" >
                        {msg => (
                          <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{msg}</p>
                        )}
                      </ErrorMessage>
                    </div>
                  </div>
                  <div className="col-md-12 d-flex">
                    <button type='submit' className="btn ml-auto">Edit Address</button>
                  </div>
                </div>
              </form>
            )}
          </Formik>

        </div>
      </Modal>


    </>
  )
}

export default MyAccount
