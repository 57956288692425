import axios from 'axios';
import { getToken, getGuestId } from '../Context/AuthContext';
import { toast } from 'react-toastify';
const access_token = getToken();
const guestId = getGuestId();
const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${access_token}`
  }
});
AxiosInstance.interceptors.request.use((config) => {
  if (guestId) {
    config.data = {
      ...config.data,
      guest_user_id: guestId
    };
  }
  return config;
});
// Response interceptor
AxiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error)
    if (error.response.status == 401) {
      localStorage.removeItem('user')
      localStorage.removeItem('token')
    } else if (error.response.data.message == "Unauthenticated.") {
      localStorage.removeItem('user')
      localStorage.removeItem('token')
      // navigate('/login')
    } else if (error.message == "Network Error") {
      toast.error(error.message, {
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
      });
    } else if (error.response.data.message == "Unauthorized") {
      toast.error("Invalid username or password.", {
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
      });
    } else if (error.response.data.message == "Validation fails.") {
      toast.error("Enter correct details.", {
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }else if(error.response.data.message == "User is not active."){
      toast.error("Your request has not been approved by the administrator.", {
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }else if(error.response.data.message == "Please enter correct old password."){
      toast.error("The current password entered is incorrect.", {
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  }
);
export default AxiosInstance;