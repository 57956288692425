import React from 'react'
import contact from "../../images/contact.jpg"
import pin from "../../images/pin.webp"
import email from "../../images/email.webp"
import phone from '../../images/phone.webp'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { ToastContainer, toast } from 'react-toastify';
import AxiosInstance from '../../Api/AxiosInstance'

const ContactUs = () => {
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const submit = async (data, e) => {
        try {
            const response = await AxiosInstance.post('add-contact-us',{first_name : data.first_name , email : data.email , last_name : data.last_name , message : data.message})  
            if (response.data.success) {
                toast.success('Thank you for connecting with us!', {
                    position: "top-right",
                    autoClose: 3000,
                });
                e.target.reset();
            }
        } catch (error) {
        }
    }
    

    return (
        <>
            <div className="wrapper">
                <div className="blank_header trans"></div>
                <main className="main-content">
                    <section className="contact-area">
                        <div className="container">
                            <div className="row">
                                <div className="offset-lg-6 col-lg-6">
                                    <div className="section-title position-relative">
                                        <h2 className="title">Get in touch</h2>
                                        <p className="m-0">If you need help with a product or service, our customer support team is ready to assist you. Reach out to us at info@smmartlife.com</p>
                                        {/* <div className="line-left-style mt-4 mb-1"></div> */}
                                    </div>
                                    {/* Start Contact Form */}
                                    <div className="contact-form">
                                        <form id="contact-form" onSubmit={handleSubmit(submit)}>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <input className="form-control" type="text" name="first_naem" placeholder="First Name *" {...register('first_name', {
                                                            required: "First Name is required"
                                                        })} />
                                                        <p className='error-message mt-2' style={{ color: 'red' , fontSize:'13px'}}>{errors.first_name?.message}</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <input className="form-control" type="text" placeholder="Last Name *" name="last_naem" {...register('last_name', {
                                                            required: 'Last Name is required'
                                                        })} />
                                                        <p className='error-message mt-2' style={{ color: 'red' , fontSize:'13px'}}>{errors.last_name?.message}</p>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <input className="form-control" type="email" name="email" placeholder="Email ID *" {...register('email', {
                                                            required: "Email ID is required ",
                                                            pattern: {
                                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                message: "Enter a valid Email ID"
                                                            }
                                                        })} />
                                                        <p className='error-message error-message mt-2' style={{ color: 'red' , fontSize:'13px'}}>{errors.email?.message}</p>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <textarea className="form-control" name="message" placeholder="Message" {...register('message')}></textarea>
                                                        <p className='error-message mt-2' style={{ color: 'red' , fontSize:'13px'}}>{errors.message?.message}</p>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group mb-0">
                                                        <button className="btn btn-sm" type="submit">SUBMIT</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                    <div className="form-message"></div>
                                </div>
                            </div>
                        </div>
                        <div className="contact-left-img" style={{ backgroundImage: `url(${contact})` }}></div>
                    </section>
                    {/* End Contact Area Wrapper */}
                    {/* Start Contact Area Wrapper */}
                    <section className="section-space">
                        <div className="container">
                            <div className="contact-info">
                                <div className="contact-info-item">
                                    <img className="icon" src={pin} width="30" height="30" alt="Icon" />
                                    <p>Smmart Life Well Being LLP <br />
                                        E156, GIDC Sector – 26, Gandhinagar, <br />Gujarat 382028, India</p>
                                </div>
                                <div className="contact-info-item">
                                    <img className="icon" src={email} width="30" height="30" alt="Icon" />
                                    <Link onClick={scrollToTop} to="mailto:info@smmartlife.com">info@smmartlife.com</Link>
                                </div>
                                <div className="contact-info-item mb-0">
                                    <img className="icon" src={phone} width="30" height="30" alt="Icon" />
                                    <Link onClick={scrollToTop} to="tel:+91 81281 09009">+91 81281 09009</Link>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* End Contact Area Wrapper */}
                    <div className="map-area">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3665.5421131047397!2d72.63399697528563!3d23.259743707435998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395c2d08c825ae23%3A0xbf7072b44e345fef!2sSmmart%20Life%20Well-Being%20LLP!5e0!3m2!1sen!2sin!4v1713940628849!5m2!1sen!2sin" ></iframe>
                    </div>
                </main>
            </div>

        </>
    )
}

export default ContactUs
