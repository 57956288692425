import React, { useState, useEffect } from 'react';
import sitelogo from '../../images/sitelogo.png'
import { Link } from 'react-router-dom'
import { Tooltip } from 'antd';
import AxiosInstance from '../../Api/AxiosInstance';



const Footer = () => { 
    const [isVisible, setIsVisible] = useState(false)
    const [ContactUsDetailsData, setContactUsDetailsData] = useState([])
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Optional: Smooth scrolling animation
        });
    };
    const fetchHomePageData = async () => {

            AxiosInstance.post('home-page')
            .then(response => {
                setContactUsDetailsData(response.data.data.contact_us_details) 
                })
            .catch(error => {
            });
    
    } 
    useEffect(() => {
        fetchHomePageData()
    }, []); 

    useEffect(() => {
        // Function to handle scroll event
        const handleScroll = () => {
            // Show the button when user scrolls down beyond a certain threshold
            if (window.scrollY > 100 && !isVisible) {
                setIsVisible(true);
            } else if (window.scrollY <= 100 && isVisible) {
                setIsVisible(false);
            }
        };

        // Add event listener for scroll event
        window.addEventListener('scroll', handleScroll);

        // Cleanup function to remove event listener
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isVisible]); // Listen to changes in isVisible state variable
    


    return (
        <>
            <footer className="footer-area">
                <div className="footer-main">
                    <div className="container">
                        <div className="row">

                            <div className="col-md-6 col-lg-4">
                                <div className="widget-item">
                                    <div className="widget-about">
                                        <Link className="widget-logo" onClick={scrollToTop} to="/" >
                                            <img src={sitelogo} width="220" alt="" />
                                        </Link>
                                        <p className="desc">{ContactUsDetailsData.description ?ContactUsDetailsData.description : ''} </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-5 mt-md-0 mt-9">
                                <div className="widget-item">
                                    <h4 className="widget-title">Quick Links</h4>
                                    <ul className="widget-nav">
                                        <li><Link onClick={scrollToTop} to="/about">About Us</Link></li>
                                        <li><Link onClick={scrollToTop} to="/contact">Contact Us</Link></li>
                                        <li><Link onClick={scrollToTop} to="/Privacy-policy">Privacy Policy</Link></li>
                                        {/* <li><Link onClick={scrollToTop} to="/login">Login</Link></li> */}
                                        <li><Link onClick={scrollToTop} to="/shop">Shop</Link></li>
                                        <li><Link onClick={scrollToTop} to="/term-condition">Terms and Conditions</Link></li>
                                        <li><Link onClick={scrollToTop} to='/refunds-cancellations'>Refunds/Cancellations</Link></li>
                                        <li><Link onClick={scrollToTop} to="/shipping-policy">Shipping Policy</Link></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-3 mt-lg-0 mt-6">
                                <div className="widget-item">
                                    <h4 className="widget-title">Connect With Us</h4>

                                    <div className="f-contact">
                                        <p className="email d-flex v-center">
                                            <i className="fa fa-envelope"></i>
                                            <strong><Link onClick={scrollToTop} to="mailto:info@smmartlife.com">{ContactUsDetailsData.email_id ? ContactUsDetailsData.email_id : 'info@smmartlife.com'}</Link></strong>
                                        </p>
                                        <p className="phone d-flex v-center">
                                            <i className="fa fa-phone"></i>
                                            

                                            <strong><Link onClick={scrollToTop} to={ContactUsDetailsData.mobile_no ? `tel:+91  ${ContactUsDetailsData.mobile_no}` : 'tel:+91 81281 09009'}>+91 {ContactUsDetailsData.mobile_no ?ContactUsDetailsData.mobile_no : '81281 09009'}</Link></strong>
                                        </p>
                                    </div>

                                    <div className="widget-social mt-5">
                                        <Tooltip placement="bottom" title={"Twitter"} style={{ display: 'block', width: 700, padding: 30  }}>
                                            <a href={ContactUsDetailsData.tweeter_link ?ContactUsDetailsData.tweeter_link : '#'} target="_blank"><i className="fa fa-twitter"></i></a>
                                        </Tooltip>

                                        <Tooltip placement="bottom" title={"Facebook"}>
                                            <a href={ContactUsDetailsData.facebook_link ? ContactUsDetailsData.facebook_link : '#'} target="_blank"><i className="fa fa-facebook"></i></a>
                                        </Tooltip>

                                        <Tooltip placement="bottom" title={"Instagram"}>
                                            <a href={ContactUsDetailsData.insta_link ? ContactUsDetailsData.insta_link : '#'} target="_blank"><i className="fa fa-instagram"></i></a>
                                        </Tooltip>

                                        <Tooltip placement="bottom" title={"Youtube"}>
                                            <a href={ContactUsDetailsData.youtube_link ? ContactUsDetailsData.youtube_link : '#'} target="_blank"><i className="fa fa-youtube"></i></a>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="footer-bottom">
                    <div className="container pt-0 pb-0">
                        <div className="footer-bottom-content">
                            <p className="copyright">© 2024 Smmartlife. All rights reserved. Made with <i className="fa fa-heart"></i> by <a target="_blank" href="https://windzoon.com/"><u>Windzoon</u>.</a></p>
                        </div>
                    </div>
                </div>
            </footer>

            {
                isVisible &&
                <div id="scroll-to-top" className="scroll-to-top show" onClick={scrollToTop}><span className="fa fa-angle-up"></span></div>
            }
        </>
    )
}

export default Footer;