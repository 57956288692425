import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useAuth } from '../../Context/AuthContext'
import AxiosInstance from '../../Api/AxiosInstance';
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const Login = () => {
  const { loggedIn, guestId, user, handleLogin } = useAuth();
  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return true;
    }
    setPasswordType("password");
    return true;
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const [error, setError] = useState('')
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate()

  const submit = async (data) => {
    try {
      const response = await AxiosInstance.post('login', { sponsor_id: data.sponsor_id, password: data.password, })
      if (response.data.data.access_token) {
        localStorage.setItem("user", JSON.stringify(response.data.data));
        localStorage.setItem("token", response.data.data.access_token);
      }
      if (response.data.success == "1") {
        
        navigate("/")
        scrollToTop()
        window.location.reload()
        scrollToTop()
      }

    } catch (error) {

    }
  }
  return (
    <>
      <div className="wrapper">
        <div className="blank_header trans"></div>
        <main className="main-content">
          <section className="page-header-area pt-5 pb-5" style={{ backgroundColor: "#FFF3DA" }}>
            <div className="container">
              <div className="row">
                <div className="col-md-5">
                  <div className="page-header-st3-content text-center text-md-start">
                    <ol className="breadcrumb justify-content-center justify-content-md-start mb-0">
                      <li className="breadcrumb-item"><Link className="text-dark" onClick={scrollToTop} to="/">Home</Link></li>
                      <li className="breadcrumb-item active text-dark" aria-current="page">Account</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="section-space">
            <div className="container">
              <div className="row mb-n8">
                <div className="col-lg-3 mb-2"></div>
                <div className="col-lg-6 mb-8">
                  <div className="my-account-item-wrap">
                    <h3 className="title">Login</h3>
                    <div className="my-account-form">
                      <form onSubmit={handleSubmit(submit)}>

                        <div className="form-group mb-6">
                          <label htmlFor="login_username">Sponsor ID<sup>*</sup></label>
                          <input
                            type="text"
                            id="login_username"
                            placeholder='Sponsor ID'
                            {...register('sponsor_id',
                              {
                                required: "Sponsor ID is required ",
                              }
                            )}
                          />
                          <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.sponsor_id?.message}</p>
                        </div>

                        <div className="form-group mb-6 pwd-eye">
                          <label htmlFor="login_pwsd">Password <sup>*</sup></label>
                          <input
                            type={passwordType}
                            id="login_pwsd"
                            placeholder='Password'
                            {...register('password', {
                              required: "Password is required",
                              minLength: { value: 6, message: "Password must be at least 6 characters long" }
                            })}
                          />
                          <div onClick={() => togglePassword("password")}>
                            {passwordType === "password" ? (
                              <AiOutlineEye className='pass-view' />
                            ) : (
                              <AiOutlineEyeInvisible className='pass-view' />
                            )}
                          </div>
                          <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.password?.message}</p>
                        </div>

                        <div className="form-group d-flex align-items-center mb-14">
                          <div className="form-check d-flex">
                            <input type="checkbox" className="form-check-input" id="remember_pwsd" />
                            <label className="form-check-label" htmlFor="remember_pwsd">Remember Me</label>
                          </div>
                          <button className="btn ml-auto" type="submit">Login</button>
                        </div>

                        {error && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{error}</p>}

                        <div className="form-group">
                          <Link className="lost-password" onClick={scrollToTop} to="/forgot_password"><u>Lost your Password?</u></Link>
                        </div>
                        <div className="form-group">
                          <Link className="lost-password" onClick={scrollToTop} to="/register"><u>New to SmmartLife? Create an account</u></Link>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 mb-2"></div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </>
  )
}

export default Login