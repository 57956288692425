import React from 'react';

function Pagination(props) {
    const { currentPage, totalPages, onPageChange, pageIndex } = props;

    return (
        <>
            <div className="col-12">
                <ul className="pagination justify-content-center me-auto ms-auto mb-0 mb-sm-5">
                    {
                        currentPage > 1 &&
                            <li className="page-item">
                                <button className="page-link previous" aria-label="Previous" disabled={currentPage <= 1} onClick={() => onPageChange(currentPage - 1)}>
                                    <span className="fa fa-chevron-left" aria-hidden="true"></span>
                                </button>
                            </li>
                    }

                    {pageIndex.map((page) => (

                        <li key={page} className="page-item d-flex" >
                        
                            <button className={`page-link ${currentPage === page ? "active" : ""}`}
                                key={page}
                                onClick={() => onPageChange(page)}
                            >
                                {page}
                            </button>
                        
                    </li>
                ))}

                    {
                        currentPage < totalPages &&
                            <li >
                                <button disabled={currentPage >= totalPages} className="page-link next" aria-label="Next"
                                    onClick={() => onPageChange(currentPage + 1)}>
                                    <span className="fa fa-chevron-right" aria-hidden="true"></span>
                                </button>
                            </li>
                    }
                </ul>

            </div>
        </>
    )
}

export default Pagination;