import React from 'react'
import abt_img2 from "../../images/abt-img2.png"
import funfact1 from "../../images/funfact1.png"
import funfact2 from "../../images/funfact2.png"
import funfact3 from "../../images/funfact3.png"
import about_img from "../../images/about_img.jpg"
import icon_1 from "../../images/icon_1.png"
import icon_2 from "../../images/icon_2.png"
import icon_3 from "../../images/icon_3.png"
import icon_4 from "../../images/icon_4.png"
import vision from "../../images/vision.png"
import values from "../../images/values.png"
import mission from '../../images/mission.png'


const About = () => {

    return (
        <>
            <div className="wrapper">
                <div className="blank_header trans"></div>
                <main className="main-content">
                    <section className="page-header-area ptb-40 bg-gredient-top">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-6 col-lg-7 col-xl-5">
                                    <div className="page-header-content">
                                        <div className="small-title">About</div>
                                        <h2 className="page-header-title">We, are Smartlife</h2>
                                        <h4 className="page-header-sub-title">Best lifestyle products provider</h4>
                                        <p className="page-header-desc">We are now introducing our range of lifestyle products with keeping in mind that ultra-rich nutrition is the key element across the categories of consumables and rejuvenation.</p>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-5 col-xl-7">
                                    <div className="page-header-thumb">
                                        <img src={abt_img2} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="funfact-area section-space">
                        <div className="container">
                            <div className="funfact-list row mb-n6">
                                <div className="col-sm-6 col-lg-4 mb-6">
                                    {/* Start Funfact Item */}
                                    <div className="funfact-item">
                                        <div className="icon">
                                            <img src={funfact1} alt="" />
                                        </div>
                                        <h2 className="funfact-number">1500+</h2>
                                        <h6 className="funfact-title">Clients</h6>
                                    </div>
                                    {/* End Funfact Item */}
                                </div>
                                <div className="col-sm-6 col-lg-4 mb-6">
                                    {/* Start Funfact Item */}
                                    <div className="funfact-item">
                                        <div className="icon">
                                            <img src={funfact2} alt="" />
                                        </div>
                                        <h2 className="funfact-number">250+</h2>
                                        <h6 className="funfact-title">Extraction</h6>
                                    </div>
                                    {/* End Funfact Item */}
                                </div>
                                <div className="col-sm-6 col-lg-4 mb-6">
                                    {/* Start Funfact Item */}
                                    <div className="funfact-item">
                                        <div className="icon">
                                            <img src={funfact3} alt="" />
                                        </div>
                                        <h2 className="funfact-number">1.2M+</h2>
                                        <h6 className="funfact-title">Revenue</h6>
                                    </div>
                                    {/* End Funfact Item */}
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* End Funfact Area Wrapper */}

                    {/* Start About Area Wrapper */}
                    <section className="cosmetic-section section-space">
                        <div className="container">

                            <div className="about-content">
                                <div className="section-title mb-0">
                                    <h2 className="title">The Best Infrastructure</h2>
                                </div>

                                <p>We have vast exposure in plant-based extracts, traditional herbs/spices and modern Supercritical Fluid Extraction Technology (SCFE) for extraction/removal of bioactive components in “natural form”. We are now introducing our range of lifestyle products with keeping in mind that ultra-rich nutrition is the key element across the categories of consumables and rejuvenation.</p>
                                <p>We believe wellbeing is not just the absence of disease or illness. It’s a complex combination of a person’s physical, mental, emotional and social health factors. So, with this thought in mind we developed a range of breakthrough skincare products along with health supplements that blend the power of science with the purity of nature that too by using Supercritical Fluid Extraction (SCFE) / Supercritical CO2 Technology to help you fight the signs of aging and keep you looking and feeling youthful.</p>
                                <p>With the motto of spreading wellness and an aim to empower people financially, we extend opportunity to join our hand and do business with us.</p>
                            </div>

                            <div className="about-thumb d-flex">
                                <img src={about_img} alt="" />
                            </div>

                        </div>
                    </section>
                    {/* End About Area Wrapper */}

                    {/* Products Made Section */}
                    <section className="what-section bg2 section-space">
                        <div className="container">

                            <div className="section-title text-center">
                                <h2 className="title">What Our Products Made From?</h2>
                                <p>With the motto of spreading wellness and an aim to empower people financially</p>
                            </div>

                            <div className="row what-list">

                                <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 items">
                                    <div className="card-what d-flex">
                                        <div className="bdr-div"></div>
                                        <i className="iconbox d-flex">
                                            <img src={icon_1} alt="" />
                                        </i>
                                        <h4>Wild Harvested Ingredients</h4>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 items">
                                    <div className="card-what d-flex">
                                        <div className="bdr-div"></div>
                                        <i className="iconbox">
                                            <img src={icon_2} alt="" />
                                        </i>
                                        <h4>Supercritical Co2 Extraction</h4>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 items">
                                    <div className="card-what d-flex">
                                        <div className="bdr-div"></div>
                                        <i className="iconbox">
                                            <img src={icon_3} alt="" />
                                        </i>
                                        <h4>Retaining Vitals For Optimal Benefits</h4>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 items">
                                    <div className="card-what d-flex">
                                        <div className="bdr-div"></div>
                                        <i className="iconbox">
                                            <img src={icon_4} alt="" />
                                        </i>
                                        <h4>Core Elements For Best Effects</h4>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>


                    {/* Special Collection */}
                    <section className="vision-section section-space">
                        <div className="container relative">

                            <div className="section-title text-center">
                                <h2 className="title">What We Stand For?</h2>
                            </div>

                            <div className="row vision-list">

                                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 items">
                                    <div className="card-vission d-flex">
                                        <i className="iconbox shine">
                                            <img src={vision} alt="" />
                                        </i>
                                        <h4><strong>Vision</strong></h4>
                                        <p>Enhancing quality of health and providing holistic life</p>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 items">
                                    <div className="card-vission d-flex">
                                        <i className="iconbox shine">
                                            <img src={mission} alt="" />
                                        </i>
                                        <h4><strong>Mission</strong></h4>
                                        <p>Delivering optimally nutritious products for vitality from inside out</p>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 items">
                                    <div className="card-vission d-flex">
                                        <i className="iconbox shine">
                                            <img src={values} alt="" />
                                        </i>
                                        <h4><strong>Values</strong></h4>
                                        <p>Empowering individuals for better tomorrow</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>

                </main>
            </div>

        </>
    )
}

export default About
