import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Dashboard from './Components/Pages/Dashboard';
import About from './Components/Pages/About';
import Shop from './Components/Pages/Shop';
import ContactUs from './Components/Pages/ContactUs';
import ProductDetail from './Components/Pages/ProductDetail';
import MyAccount from './Components/Pages/MyAccount';
import Login from './Components/Pages/Login';
import ForgotPassword from './Components/Pages/ForgotPassword';
import Cart from './Components/Pages/Cart';
import Checkout from './Components/Pages/Checkout';
import ThankYou from './Components/Pages/ThankYou';
import ProductCompare from './Components/Pages/ProductCompare';
import ProductWishlist from './Components/Pages/ProductWishlist';
import Register from './Components/Pages/Register';
import { AuthProvider } from './Context/AuthContext';
import Header from './Components/Home/Header';
import Footer from './Components/Home/Footer';
import ProtectedRoute from './Context/ProtectedRoute';
import PrivacyPolicy from './Components/Pages/PrivacyPolicy';
import ChangePassword from './Components/Pages/ChangePassword';
import TermAndCondition from './Components/Pages/TermAndCondition';
import Refund from './Components/Pages/Refund';
import ShippingPolicy from './Components/Pages/ShippingPolicy';
function App() {


  return (
    <>
      <AuthProvider>
        <Header />
        <Routes>
          <Route path='/' element={<Dashboard />} />
          <Route path='/about' element={<About />} />
          <Route path='/shop' element={<Shop />} />
          <Route path='/product-category/:slug' element={<Shop />} />
          <Route path='/contact' element={<ContactUs />} />
          <Route path='/product/:slug' element={<ProductDetail />} />
          <Route path='/wishlist' element={<ProductWishlist />} />
          <Route path='/Privacy-policy' element={<PrivacyPolicy/>}/>
          <Route path='/term-condition' element={<TermAndCondition/>}/>
          <Route path='/refunds-cancellations' element={<Refund/>}/>
          <Route path='/shipping-policy' element={<ShippingPolicy/>}/>
          <Route path='/login' element={<Login />} />
          <Route path='/forgot_password' element={<ForgotPassword />} />
          <Route path='/cart' element={<Cart />} />
          <Route path='/compare' element={<ProductCompare />} />
          <Route path='/wishlist' element={<ProductWishlist />} />
          <Route path='/register' element={<Register />} />
          <Route path='/myaccount' element={<ProtectedRoute element={MyAccount} />} />
          <Route path='/checkout' element={<ProtectedRoute element={Checkout} />} />
          <Route path='/ThankYou/:id' element={<ProtectedRoute element={ThankYou} />} />
          <Route path='/change-password' element={<ProtectedRoute element={ChangePassword} />} />
        </Routes>
        <Footer />
      </AuthProvider>
    </>
  );
}

export default App;