import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie';
import { scrollToTop } from '../sections/ScrollToTop';
import AxiosInstance from '../../Api/AxiosInstance';
import Loader from './Loader'
import { ToastContainer, toast } from 'react-toastify';

const ProductWishlist = () => {
    const [cartdata, setCartdata] = useState([]);
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true);
    const fetchProduct = async () => {
        try {
            const response = await AxiosInstance.post('cart-lists', { type_id: 2 })
            setCartdata(response.data.data.items)
            setLoading(false);
        } catch (error) {

        }
    }
    useEffect(() => {
        fetchProduct()
    }, [cartdata.length])

    const quantityCount = async (data) => {
        try {
            setLoading(true)
            const response = await AxiosInstance.post('update-cart-lists', { cart_id: data[0], quantity: data[1] })
            document.dispatchEvent(new Event('itemAddedToCart'));
            fetchProduct()
        } catch (error) {
        }
    }

    function addToCart(id, price, qty, cart_id) {
        setLoading(true);
        const obj = { 'product_id': id, 'price': price, 'quantity': qty, 'sub_total': price * qty }
        AxiosInstance.post('add-to-cart', obj)
            .then((response) => {
                if (response.data.success) {
                    document.dispatchEvent(new Event('itemAddedToCart'));
                    toast.success('Item added to cart successfully.', {
                        autoClose: 3000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    quantityCount([cart_id, 0]);
                    // setLoading(false);

                } else if (response.status === 400 && response.message === "Token is expired!!") {
                    navigate("/login");
                    window.location.reload()
                }
            }).catch((error) => {
            })
    }


    return (
        <>
            <div className="wrapper">
                <div className="blank_header trans"></div>
                <main className="main-content">
                    <nav aria-label="breadcrumb" className="breadcrumb-style1">
                        <div className="container">
                            <ol className="breadcrumb justify-content-center">
                                <li className="breadcrumb-item"><Link onClick={scrollToTop} to="/">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Wishlist</li>
                            </ol>
                        </div>
                    </nav>

                    {
                        loading ?
                            <Loader /> :
                            (
                                cartdata.length ?
                                    <section className="wishlist-section section-space">
                                        <div className="container">
                                            <div className="shopping-wishlist-form table-responsive">
                                                <form action="#" method="post">
                                                    <table className="table text-center">
                                                        <thead>
                                                            <tr>
                                                                <th className="product-remove">&nbsp;</th>
                                                                <th className="product-thumbnail">&nbsp;</th>
                                                                <th className="product-name">Product name</th>
                                                                <th className="product-price">Unit price</th>
                                                                <th className="product-stock-status">Stock status</th>
                                                                <th className="product-add-to-cart">&nbsp;</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {cartdata.map((item) => (
                                                                <tr className="tbody-item" key={item.id}>
                                                                    <td className="product-remove">
                                                                        <a className="remove" onClick={() => quantityCount([item.cart_id, 0])} >×</a>
                                                                    </td>
                                                                    <td className="product-thumbnail">
                                                                        <div className="thumb">
                                                                            <Link onClick={scrollToTop} to={`/product/${item.slug}`}>
                                                                                <img src={item.file_url[0]} width="70" alt="" />
                                                                            </Link>
                                                                        </div>
                                                                    </td>
                                                                    <td className="product-name">
                                                                        <Link className="title" onClick={scrollToTop} to={`/product/${item.slug}`}>{item.title}</Link>
                                                                    </td>
                                                                    <td className="product-price">
                                                                        <span className="price">₹{item.price.toFixed(2)}</span>
                                                                    </td>
                                                                    <td className="product-stock-status">
                                                                        <span className="wishlist-in-stock">{item.available_unit > 0 ? "In Stock" : "Out Of Stock"}</span>
                                                                    </td>


                                                                    {
                                                                        item.available_unit > 0 ?
                                                                            <td className="product-add-to-cart">
                                                                                <button type="button" className="btn-shop-cart"
                                                                                    onClick={() => { addToCart(item.id, item.price, 1, item.cart_id) }}>
                                                                                    Add to Cart
                                                                                </button>
                                                                            </td>
                                                                            :
                                                                            <td className="product">
                                                                            <div className="btn-shop-cart">
                                                                                Out Of Stock
                                                                            </div>
                                                                            </td>
                                                                    }


                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </form>
                                            </div>
                                        </div>
                                    </section>
                                    :
                                    <div>
                                        <br />
                                        <h3 className='text-center'>No products added to the wishlist</h3>
                                        <br />
                                        <div className="text-center">
                                            <button onClick={() => navigate('/shop')} className="btn ml-auto" >Return to Shop</button>
                                        </div>
                                        <br />
                                    </div>
                            )
                    }


                </main>
            </div>

        </>
    )
}

export default ProductWishlist
